import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Typography} from "@material-ui/core";
import {graphql, useStaticQuery} from "gatsby"
import RechtsgebietCard from "../components/RechtsgebietCard";
import Layout from "../components/Layout";
import Image from "gatsby-image";


const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        flexGrow: 1,
    },
    button: {
        textAlign: 'left'
    },
}));


export default function App() {

    const classes = useStyles();

    const data = useStaticQuery(graphql`
    {
      rechtsgebieteVerkehrsrecht: allContentfulRechtsgebiet(filter: {category: {eq: "Verkehrsrecht"}}, sort: {fields: weight, order: ASC}) {
        rechtsgebiete: nodes {
          slug
          category
          title
          summary {
            summary
          }
          weight
          cardImage {
            fluid(maxWidth: 384, maxHeight: 216, resizingBehavior: FILL, quality: 95) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      adacVertragsanwaltImage:file(relativePath:{eq: "dreyer-pfeiffer-adac-vertragsanwalt.jpg"}){
        img:childImageSharp {
          fluid(maxWidth: 384, maxHeight: 216, fit: FILL) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allCategories: allContentfulKategorie {
        categories:nodes {
          title
          slug
          heroImage {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `);

    const currentCategory = data.allCategories.categories.find(category => category.title === 'Verkehrsrecht');
    const rechtsgebieteTiles = data.rechtsgebieteVerkehrsrecht.rechtsgebiete.map((rechtsgebiet, i) => {
        return (
            <RechtsgebietCard rechtsgebiet={rechtsgebiet} categoryPath="verkehrsrecht"/>
        )
    });
    return (
        <Layout
            heroHeadline1="Verkehrsrecht"
            heroHeadline2=""
            heroImage={currentCategory.heroImage.fluid}
        >

            <Container className={classes.cardGrid}>
                <Grid container spacing={4}>

                    {rechtsgebieteTiles}

                    {/*Special tile for ADAC Vertragsanwalt added manually to the end of the list.*/}
                    <Grid item key={1} xs={12} sm={6} md={4}>
                        <Card className={classes.card}>
                            <CardMedia
                                component={Image}
                                fluid={data.adacVertragsanwaltImage.img.fluid}
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    ADAC-Vertragsanwältin
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button className={classes.button} size="small" color="primary">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        color="inherit"
                                        key={1}
                                        variant="button"
                                        href="https://pfeiffer-muenchen.adac-vertragsanwalt.de/"
                                    >
                                        pfeiffer-muenchen.adac-vertragsanwalt.de
                                    </a>
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>

                </Grid>
            </Container>

        </Layout>
    );
}
